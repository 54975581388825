.react-calendar {
  border: none;
  width: 100%;
}

.react-calendar > * {
  font-family: "GillSansC" !important;  
}

.react-calendar__tile--now {
  background: lightyellow;
  color: #131415;
}

.react-calendar__tile--active {
  background: #007DB7;
  color: white;
}

.react-calendar__navigation {
  font-size: 16px;
  font-weight: 700;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 11px;

  & > abbr {
    text-decoration: none;
  }
}

.react-calendar__month-view__days__day--neighboringMonth {
    color: lightgrey;
}

