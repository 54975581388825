.ant-input {
  border-color: #66aed1;
  border-radius: 5px;
  font-size: 16px;
  color: #000000;
  height: 30px;
  padding: 2px 10px;
}

.ant-input-number {
  border-color: #66aed1;
  border-radius: 5px;
  color: #000000;
  height: 30px;
  line-height: 30px;
}

.ant-picker {
  border-color: #66aed1;
  border-radius: 5px;
  height: 30px;
  line-height: 30px;
  width: 100%;
}

.ant-picker-input > input {
  color: #000000;

}
