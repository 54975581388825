.ant-tabs {
  width: 100%;
  color: #ada8a8;
  user-select: none;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #575353;
}

.ant-tabs-tab:hover {
  color: #575353;
}

.ant-tabs-ink-bar {
  height: 5px;
  background: #575353 !important;
}