.ant-table-thead .ant-table-cell {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  background-color: #000000;
  user-select: none;
  border-right-width: 1px;
  border-right-color: #66aed1;
  border-right-style: solid;
}

/* Style for table body cells */
.ant-table-tbody .ant-table-cell {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000; /* Black color for text */
  border-bottom-color: #66aed1; /* Blue color for the bottom border */
}

/* Style for active pagination item */
.ant-pagination-item-active {
  border-color: #000000;
  a {
    color: #000000;
    text-decoration: underline;
  }
}

/* Style for clickable rows */
.clickableRow {
  cursor: pointer;
}
