

.ant-form label {
  color: #000000;
  font-size: 15px;
}
.ant-modal-title  {
  color: #FFFFFF;
  font-size: 17px;
}
