.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #66aed1;
  border-radius: 5px;
}

.ant-select-selector {
  font-weight: normal;
  font-size: 21px;
  color: #000000;
}



.ant-select-arrow {
  font-size: 16px;
  line-height: 22px;
  top: 45%;
  color: #66aed1;
}

.ant-select-item-option {
  color: #000000;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled)  {
  background: #66aed1;
  color: white;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)  {
  background: #66aed1;
  color: white;
}



.ant-pagination-options-size-changer.ant-select {
  .ant-select-selector {
    min-width: auto;
  }
}

.moment-camera-selector {
  .ant-select-selector {
    font-weight: normal;
    font-size: 14px;
    color: #66aed1;
  }
}
